// 业务领域
<template>
  <div class="service-business">
    <ul class="business-nav align-center flex-warp" :style="{height:showAll?'auto':'38px'}">
      <li :class="['nav-item', activeId==index?'nav-item-active':'']" v-for="(item,index) in business" :key="item.id"
        @click="activeId=index">
        {{item.name}}
      </li>
      <img :class="['nav-arrow',showAll?'nav-arrow-show':'']" :src="require('../../assets/images/arrow1.png')" alt=""
        @click="showAll=!showAll">
    </ul>
    <div class="business-content">
      <div class="content-pang" v-html="business[activeId].text"></div>
    </div>
  </div>
</template>

<script>
import { getBusiness } from '@/api'
export default {
  name: 'Business',

  data () {
    return {
      business: [{}],
      activeId: 0,
      showAll: false
    };
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      let { data } = await getBusiness()
      this.business = data
    },
  },
};
</script>

<style scoped lang="less">
.business-nav {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
  padding-right: 20px;
  .nav-item {
    padding: 10px 15px;
    border-radius: 19px;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
    &.nav-item-active {
      background-color: #fbd690;
      color: #000;
      font-weight: bold;
    }
  }
  .nav-arrow {
    position: absolute;
    top: 10px;
    right: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: transform 0.3s;
  }
  .nav-arrow-show {
    transform: rotate(180deg);
  }
}
/deep/ .business-content {
  min-height: 500px;
  background-color: #ffffff;
  padding: 70px 62px;
  overflow: hidden;
  position: relative;
  &::before {
    content: "";
    width: 230px;
    height: 402px;
    position: absolute;
    top: 0;
    right: 0;
    background: url("../../assets/images/ywly-bg1.png") no-repeat;
    background-size: 100% 100%;
  }
  &::after {
    content: "";
    width: 432px;
    height: 341px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: url("../../assets/images/ywly-bg2.png") no-repeat;
    background-size: 100% 100%;
  }
  .content-pang {
    position: relative;
    z-index: 2;
    .list-item {
      margin-bottom: 45px;
    }
    .business-info-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .business-info-text {
      line-height: 30px;
    }
  }
}
</style>
